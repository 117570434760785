<template>
  <div>
    <!-- Service Section -->
    <section class="service-section">
      <div class="container">
        <div class="service-content">
          <!-- Left side: Image -->
          <div class="service-image">
            <img src="../assets/images/bg4.png" alt="Service Img">
          </div>
          <!-- Right side: Content -->
          <div class="service-details">
            <p class="service-description">
              An Energy Service Platform that simplifies the job delivery process through the integrated management solution of AFE costing, ordering, service scheduling and the seamless supply and delivery of consumables tailored to your project site. Project Managers and Procurement Departments can efficiently manage their services, rentals, supplies, QC data, PO billing and more.
            </p>
          </div>
        </div>
      </div>
    </section>
 
<!-- Testimonial Section -->
<div class="text-center px-10">
    <h1 class="text-2xl font-semibold mb-6">Choose a service</h1>
    <div class="grid md:grid-cols-4 gap-4 justify-items-center px-6">
        <div v-for="(vendor) in vendors" :key="vendor.id" class="my-4 group relative">
            <a v-if="vendor" :href="`${vendor.id}/`+sanitizeTitle(`${vendor.slug}`)">
                <div class="flex flex-col rounded-lg shadow-lg w-60 h-50 bg-gray-50 overflow-hidden group-hover:bg-gray-300 transition-colors duration-300 relative">
                    <div class="relative">
                        <img v-if="vendor" v-bind:src="vendor.logo" class="w-32 h-32 object-cover object-center mx-auto group-hover:opacity-50 transition-opacity duration-300">
                        <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <button class="bg-white text-black px-4 py-2 rounded-lg">Place Order</button>
                        </div>
                    </div>
                    <div class="flex flex-col justify-between p-4 h-1/2 bg-white">
                        <p class="text-sm font-bold" style="margin-bottom: 0.5rem;">{{vendor.name}}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
 
 
 
 
 
 
    <!-- How to Use Our Mobile App Section -->
    <div class="mt-8 md:mt-20 h-auto" v-if="settings">
      <div class="container max-w-6xl px-5 mx-auto">
        <h2 class="text-3xl md:text-4xl font-bold leading-tight text-gray-800 mb-6 text-center">How To Use Our Mobile App</h2>
 
      <!--  <div class="grid gap-8 md:grid-cols-2 items-center">
          
          <div class="pb-8 md:pb-0">-->
           
         <!--   <div
              class="border rounded-lg p-6 cursor-pointer mb-4"
              :style="{ backgroundColor: activeStep === 1 ? 'rgb(255,218,216)' : 'white' }"
              @click="activeStep = 1"
            >
              <h4 class="text-2xl md:text-3xl font-bold leading-tight text-gray-800 mb-3">
                1. Choose a service
              </h4>
              <p class="text-base md:text-lg font-light leading-normal text-gray-600">
                Select GoRide, GoFood, or GoSend
              </p>
            </div>
            -->
 
           
          <!--  <div
              class="border rounded-lg p-6 cursor-pointer mb-4"
              :style="{ backgroundColor: activeStep === 2 ? 'rgb(255,218,216)' : 'white' }"
              @click="activeStep = 2"
            >
              <h4 class="text-2xl md:text-3xl font-bold leading-tight text-gray-800 mb-3">
                2. Put in your details
              </h4>
              <p class="text-base md:text-lg font-light leading-normal text-gray-600">
                Enter in your addresses
              </p>
            </div>-->
 
            
        <!--    <div
              class="border rounded-lg p-6 cursor-pointer mb-4"
              :style="{ backgroundColor: activeStep === 3 ? 'rgb(255,218,216)' : 'white' }"
              @click="activeStep = 3"
            >
              <h4 class="text-2xl md:text-3xl font-bold leading-tight text-gray-800 mb-3">
                3. Enjoy the journey
              </h4>
              <p class="text-base md:text-lg font-light leading-normal text-gray-600">
                Get ready for your ride/food/delivery!
              </p>
            </div>-->
          </div>
 
          <!-- Right Section - Image -->
          <div class="mobile flex justify-center">
            <img src="../assets/images/9.png" class="max-w-full h-auto mx-auto md:max-h-full py-6" alt="Mobile App Image">
          </div>
       <!-- </div>
      </div>-->
    </div>
 
       <!-- How does Mxjek Work -->
        <section class="how-section" style="margin-top: 4rem;">
          <div class="container">
            <div class="how-content">
              <div class="col-lg-12 col-md-12">
                <center class="text-3xl md:text-4xl font-bold leading-tight text-gray-800 mb-6">
                  <h5>How does Lomac’s Digital Service Center Work?</h5>
                </center>
                <div class="row text-center d-flex align-items-center justify-content-center flex-wrap">
                  <div class="service-item">
                    <div class="icon"><img src="../assets/images/3.png" alt="Login icon"></div>
                    <h4 class="title" style="font-size: 1.25rem; font-weight: 600; color: #333; margin-top: 10px; margin-bottom: 8px;"><a>Login</a></h4>
                    <p class="text-base md:text-lg font-light leading-normal text-gray-600">Download the app and apply for access.</p>
                  </div>
                  <div class="service-item">
                    <div class="icon"><img src="../assets/images/4.png" alt="Service icon"></div>
                    <h4 class="title" style="font-size: 1.25rem; font-weight: 600; color: #333; margin-top: 10px; margin-bottom: 8px;"><a>Choose Service</a></h4>
                    <p class="text-base md:text-lg font-light leading-normal text-gray-600">Choose from our range of services to book Professional Services/Field Services/Rentals/Supplies.</p>
                  </div>
                  <div class="service-item">
                    <div class="icon"><img src="../assets/images/5.png" alt="Booking icon"></div>
                    <h4 class="title" style="font-size: 1.25rem; font-weight: 600; color: #333; margin-top: 10px; margin-bottom: 8px;"><a>Book Service</a></h4>
                    <p class="text-base md:text-lg font-light leading-normal text-gray-600">See your AFE cost estimate in advance and schedule your estimated Start Date and Completion Date. Select payment option.</p>
                  </div>
                  <div class="service-item">
                    <div class="icon"><img src="../assets/images/6.png" alt="Tracking icon"></div>
                    <h4 class="title" style="font-size: 1.25rem; font-weight: 600; color: #333; margin-top: 10px; margin-bottom: 8px;"><a>Track Your Service</a></h4>
                    <p class="text-base md:text-lg font-light leading-normal text-gray-600">Track your purchased service in real-time. Get in-app and real-time chat notifications.</p>
                  </div>
                  <div class="service-item">
                    <div class="icon"><img src="../assets/images/7.png" alt="Completed icon"></div>
                    <h4 class="title" style="font-size: 1.25rem; font-weight: 600; color: #333; margin-top: 10px; margin-bottom: 8px;"><a>It's Completed!</a></h4>
                    <p class="text-base md:text-lg font-light leading-normal text-gray-600"> Change to “Get the services done. Rate and share your feedback to aid in the continued improvement of deliverability.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
 
    <!-- About -->
    <section class="about-section">
      <div class="container2">
        <div class="about-content">
          <!-- Left side: Image -->
          <div class="about-image">
            <img src="../assets/images/bg2.png" alt="Service Img">
          </div>
          <!-- Right side: Content -->
          <div class="about-details">
            <h1 class="about-description2">
              Lomac Service Center: Your Gateway to Unmatched Reliability
            </h1>
            <p class="about-description3">A partnership You Can Count On</p>
            <div class="flex justify-center space-x-4">
  <div class="relative inline-block overflow-hidden">
    <button>
      <img src="/img/play-store.png" class="h-20 md:h-24" alt="Google Play Store">
    </button>
  </div>
  <div class="relative inline-block overflow-hidden">
    <button>
      <img src="/img/app-store.png" class="h-20 md:h-24" alt="Apple App Store">
    </button>
  </div>
</div>
          </div>
        </div>
      </div>
    </section>
 
  </div>
 
 
 
</template>
 
<script>
import axios from 'axios';
 
export default {
  name: 'LandingPage',
 
  data() {
    return {
      vendors: null,
      settings: null,
      base_url: this.$store.state.baseUrl,
      activeStep: 1 // Default active step
    };
  },
 
  mounted() {
    axios.get(this.base_url + 'api/app/settings')
      .then((response) => {
        this.settings = response.data;
      })
      .catch(error => console.log(error));
 
    axios.get(this.base_url + 'api/vendor/types')
      .then((response) => {
        this.vendors = response.data;
      })
      .catch(error => console.log(error));
  },
 
  methods: {
    sanitizeTitle(title) {
      return title.toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/^-+|-+$/g, '');
    },
  },
};
</script>
 
<style scoped>
/* General styles for service section */
.service-section {
  padding: 4rem 0;
  background-color: #f9f9f9;
}
 
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
 
.service-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
 
.service-image {
  flex: 1;
}
 
.service-image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Smooth scale transition */
}
 
.service-image img:hover {
  transform: scale(1.05); /* Scale up on hover */
}
 
.service-details {
  flex: 1;
  padding: 0 20px;
  color: #666;
  margin-left: 20px; /* Add margin to the left side content */
}
 
.service-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}
 
/* Responsive styles for medium screens */
@media (max-width: 1024px) {
  .service-content {
    flex-direction: column;
    text-align: center;
  }
  .service-details {
    margin-left: 0;
    padding-top: 20px;
  }
  .service-image img {
    width: 100%;
  }
}
 
/* Responsive styles for small screens */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column;
  }
  .service-details {
    padding: 0 10px;
  }
  .service-description {
    font-size: 1rem;
    line-height: 1.4;
  }
}
 
/* Responsive styles for extra small screens */
@media (max-width: 480px) {
  .service-section {
    padding: 2rem 0;
  }
  .service-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .service-image img {
    width: 100%;
  }
  .service-details {
    padding: 0 10px;
  }
  .service-description {
    font-size: 0.9rem;
    line-height: 1.3;
  }
}
 
 
/* Base styles */
.text-center {
  padding-top: 40px;
    text-align: center;
}
.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.text-2xl {
    font-size: 1.5rem;
}
.font-semibold {
    font-weight: 600;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.grid {
    display: grid;
}
.md\:grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}
.gap-4 {
    gap: 1rem;
}
.justify-items-center {
    justify-items: center;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.group {
    position: relative;
}
.relative {
    position: relative;
}
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.w-72 {
    width: 18rem;
}
.h-64 {
    height: 16rem;
}
.bg-gray-50 {
    background-color: #f9fafb;
}
.bg-gray-300 {
    background-color: #d1d5db;
}
.overflow-hidden {
    overflow: hidden;
}
.transition-colors {
    transition: background-color 0.3s;
}
.duration-300 {
    transition-duration: 300ms;
}
.relative {
    position: relative;
}
.w-32 {
    width: 8rem;
}
.h-32 {
    height: 8rem;
}
.object-cover {
    object-fit: cover;
}
.object-center {
    object-position: center;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.group-hover\:opacity-50:hover {
    opacity: 0.5;
}
.group-hover\:opacity-100:hover {
    opacity: 1;
}
.absolute {
    position: absolute;
}
.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.bg-black {
    background-color: #000;
}
.bg-opacity-50 {
    background-color: rgba(0, 0, 0, 0.5);
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.bg-white {
    background-color: #fff;
}
.text-black {
    color: #000;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.text-sm {
    font-size: 0.875rem;
}
.font-bold {
    font-weight: 700;
}
.text-xs {
    font-size: 0.75rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.text-gray-600 {
    color: #4b5563;
}
 
/* Responsive styles */
@media (max-width: 1024px) {
    .md\:grid-cols-4 {
        grid-template-columns: repeat(2, 1fr);
    }
    .w-72 {
        width: 100%;
    }
    .h-64 {
        height: auto;
    }
    .w-32 {
        width: 6rem;
    }
    .h-32 {
        height: 6rem;
    }
}
 
@media (max-width: 640px) {
    .px-10 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .text-2xl {
        font-size: 1.25rem;
    }
    .mb-6 {
        margin-bottom: 1rem;
    }
    .px-6 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .w-72 {
        width: 100%;
    }
    .h-64 {
        height: auto;
    }
    .w-32 {
        width: 5rem;
    }
    .h-32 {
        height: 5rem;
    }
}
 
 
 
 
/* how to use MX JEK */
.mobile{
  height: 85vh;
}
 
.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
 
.store-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%; /* Set consistent width */
  height: 100%; /* Set consistent height */
}
 
.store-button img {
  max-height: 100%;
  max-width: 100%;
  height: 100%; /* Maintain aspect ratio */
  width: 100%; /* Maintain aspect ratio */
}
 
.how-section .container {
  padding: 20px;
}
 
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
 
.service-item {
  flex: 1 1 30%; /* Adjust this value as needed */
  margin: 10px;
  max-width: 300px; /* Adjust based on your design */
  box-sizing: border-box;
}
 
.icon img {
  width: 100%; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}
 
 
.title a {
  text-decoration: none;
  color: #333;
}
 
.description {
  font-size: 14px;
  color: #666;
}
 
.icon{
  margin-bottom:20px;
}
 
 
/* about section css */
 
/* Base styles (default for large screens) */
.about-section {
  padding: 4rem 0;
  background-color: #F5F5F5;
}
 
.container2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
 
.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
 
.about-image {
  flex: 1;
  height: 60%;
  width: 70%;
}
 
.about-image img {
  display: block;
  width: 100%;
  height: auto; /* Changed to auto to maintain aspect ratio */
}
 
.about-details {
  flex: 1;
  padding: 0 20px;
  color: #666;
}
 
.about-description {
  color: #666;
}
 
.about-description2 {
  font-size: 2rem;
  padding-bottom: 8px;
  font-weight: 700;
  color: #000;
}
 
.about-description3 {
  padding-bottom: 8px;
  color: #000;
}
 
/* Small screens (mobile devices) */
@media (max-width: 767px) {
  .about-section {
    padding: 2rem 0;
  }
 
  .container2 {
    padding: 0 10px;
  }
 
  .about-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
 
  .about-image {
    width: 100%;
    height: auto;
  }
 
  .about-details {
    padding: 0 10px;
  }
 
  .about-description2 {
    font-size: 1.5rem;
  }
 
  .about-description3 {
    font-size: 1rem;
  }
}
 
/* Large screens (desktop devices) */
@media (min-width: 1024px) {
  .about-content {
    gap: 60px;
  }
 
  .about-image {
    width: 60%;
  }
 
  .about-description2 {
    font-size: 2.5rem;
  }
 
  .about-description3 {
    font-size: 1.2rem;
  }
}
 
 
</style>