<template>
    <div class="cart">
        <section class="container max-w-6xl px-5 mx-auto mt-10 mb-5" v-if="settings">
            <div class="cart-container">
                <p class="title">Your Cart</p>
                <div class="empty-cart" v-if="this.$store.state.cart.length <= 0">
                    <p>No items in cart.</p>  
                </div>
                <div class="cart-content grid gap-4 md:grid-cols-3" v-else>
                    <div class="cart-items md:col-span-2">
                        <div class="cart-item relative px-5 py-3 mx-auto mb-5 shadow-md md:justify-between rounded-xl md:flex md:space-x-10" v-for="product in products" :key="product.id">
                            <button @click="removeProduct(product.id)" class="remove-product-button absolute top-0 right-0 p-1 bg-red-500 rounded-md shadow-md">
                                <MinusSmIcon class="icon w-3 h-3 mt-1 text-white cursor-pointer md:w-3 md:h-3" aria-hidden="true" />
                            </button>
                            <a :aria-label="product.name" :href="`product/${product.id}/`+sanitizeTitle(`${product.name}`)">
                                <img v-if="product" v-bind:src="product.photo" class="product-image mx-auto mt-3 md:h-16">
                            </a>
                            <div class="product-details flex flex-col">
                                <a :aria-label="product.name" :href="`product/${product.id}/`+sanitizeTitle(`${product.name}`)">
                                    <div class="product-name flex self-center">
                                        <p class="m-0 mt-4 text-gray-600 md:mt-0">{{ product.name }}</p>
                                    </div>
                                </a>
                                <a :aria-label="product.vendor.name" :href="`vendor/${product.vendor.id}/`+sanitizeTitle(`${product.vendor.name}`)">
                                    <div class="vendor-name self-center">
                                        <p class="mt-1 text-xs text-gray-600 ">{{ product.vendor.name }}</p>
                                    </div>
                                </a>
                            </div>
                            <div class="quantity-price flex flex-row self-center space-x-2">
                                <p class="quantity-label text-xs">Quantity:</p>
                                <p class="quantity-value text-xs font-semibold">{{ product.quantity }}</p>
                            </div>
                            <div class="price-info flex flex-col self-center">
                                <div class="flex justify-between space-x-2">
                                    <p class="price-label text-xs">Price:</p>
                                    <span v-if="product.discount_price > 0">
                                        <p class="price text-sm text-gray-600">{{ currency }}{{ product.discount_price.toFixed(2) }}</p>
                                    </span>
                                    <span v-else>
                                        <p class="price text-sm text-gray-600">{{ currency }}{{ product.price.toFixed(2) }}</p>
                                    </span>
                                </div>
                                <div class="flex justify-between space-x-2">
                                    <p class="text-xs">Total Product Price:</p>
                                    <p class="flex self-center text-sm text-gray-600">{{ currency }}{{ product ? product.totalPrice.toFixed(2) : '' }}</p>
                                </div>
                                <div v-if="product.selectedOption">
                                    <p class="text-xs" v-if="product.selectedOption.length == 0">No Extras</p>
                                    <p class="text-xs underline" v-if="product.selectedOption.length != 0">Extras:</p>
                                    <div class="extras flex flex-col">
                                        <div class="flex justify-between space-x-2">
                                            <p class="text-xs" v-for="option in product.selectedOption" :key="option.id">{{ option.name }}</p>
                                        </div>
                                        <div class="flex justify-between space-x-2">
                                            <p class="text-sm text-gray-600" v-for="option in product.selectedOption" :key="option.id">{{ currency }} {{ option.price }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="order-summary md:col-span-1" v-show="products">
                        <p class="summary-title py-3 text-sm font-semibold text-gray-600 md:mt-0">Order Summary</p>
                        <div class="summary-info font-bold divide-y divide-black">
                            <p class="text-xs font-light text-gray-600 md:mb-3 md:mt-0">This subtotal doesn't include the delivery or pickup cost</p>
                        </div>
                        <div class="flex justify-between mt-4">
                            <p class="text-sm font-light text-gray-600">Total Item(s)</p>
                            <p class="font-bold text-black">{{ cartItemCount }}</p>
                        </div>
                        <div class="flex justify-between mt-1">
                            <p class="text-sm font-light text-gray-600">SubTotal</p>
                            <span v-if="cartSubTotal">
                                <p class="font-bold text-black">{{ currency }} {{ cartSubTotal.toFixed(2) }}</p>
                            </span>
                        </div>
                        <div class="flex justify-between mt-1">
                            <p class="text-sm font-light text-gray-600">Discount</p>
                            <p class="font-bold text-black" v-if="discount">
                                <span v-if="discount.percentage == 1">%</span>
                                <span v-else>{{ currency }}</span>
                                {{ discount.discount }}
                            </p>
                        </div>
                        <div class="py-4 font-bold divide-y-2 divide-black divide-dashed">
                            <div></div>
                            <div></div>
                        </div>
                        <div class="flex justify-between mt-1">
                            <p class="text-sm font-semibold text-gray-600">Total Amount</p>
                            <span v-if="totalAmt">
                                <p class="text-sm font-bold text-black md:text-base">{{ currency }} {{ totalAmt.toFixed(2) }}</p>
                            </span>
                        </div>
                        <div v-show="isLoggedIn">
                            <a :href="`checkout`" v-if="settings" class="checkout-button flex justify-between w-full px-5 py-10 mt-10 rounded" :style="{ 'background-color': settings.colors.primaryColor }">
                                <p class="self-center font-semibold text-primary">Continue to checkout</p>
                                <span class="self-center w-12 h-12 p-3 text-2xl font-light text-center text-black bg-white rounded-full">
                                    <ArrowCircleRightIcon class="w-6 h-6 text-black" aria-hidden="true" />
                                </span>
                            </a>
                        </div>
                            <div class=" butn text-center">
                                <button @click="getCoupon" class="coupon-button px-6 py-3 mt-5 font-semibold border-2 border-gray-300 rounded-full" type="submit">Buy Now</button>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
 
<script>
import axios from 'axios'
import Download from '@/components/Downloads.vue'
import { UserIcon, ArrowCircleRightIcon, MinusSmIcon, ShoppingBagIcon } from '@heroicons/vue/outline'
import { notify } from "@kyvg/vue3-notification"
 
export default {
  name: 'cart',
  components: {
    Download,
    UserIcon,
    ArrowCircleRightIcon,
    MinusSmIcon,
    ShoppingBagIcon
    // MenuTab
  },
  data () {
    return {
        isLoggedIn: false,
        products: null,
        product: null,
        currency: null,
        quantity: 1,
        coupon: null,
        discount: null,
        animationDown :{
            translateY:'translateY(-100%)',
            // eslint-disable-next-line no-dupe-keys
            translateY:'translateY(100%)'
        },
        AuthStr: 'Bearer ' + localStorage.getItem('authToken'),
        orders: null,
        settings: null,
        base_url: this.$store.state.baseUrl
    }
  },
  computed: {
 
    increment(product) {
         //  let item = this.$store.state.cart.find(p => p.id == product.id);
         // console.log(item.quantity)
        this.$store.commit('increment', product)
    },
     
    decrement(product) {
        this.$store.commit('decrement', product);
    },
 
    cartSubTotal() {
        let total = 0;
        // let totalAmt = 0;
        for (let product of this.$store.state.cart) {
            total += Number(product.totalPrice);
            if(product.selectedOption[0]){
                product.selectedOption.forEach((item, i) => {
                    total += Number(product.selectedOption[i].price);
                })
            }else if(this.discount){
                if(this.discount.percentage == 1){
                    total *= this.discount.discount/ 100;
                }else if(this.discount.percentage == 0) {
                    total -= this.discount.discount;
                }
            }
           
        }
        return total;
    },
 
    cartItemCount() {
      return this.$store.getters.count;
    },
 
    totalAmt(){
        //sum totalprices of products in the cart and divide or subtract discount from it
        //determine whether discount is % or a fixed amount to enable you to calculate the totalAmt
       let total = 0;
        total = Number(this.cartSubTotal);
        return total;
    }
},
  mounted () {
    if (localStorage.getItem("authToken")) {
      this.isLoggedIn = true;
      this.user = JSON.parse(localStorage.getItem("user"))
    }
 
    if (localStorage.getItem("cart")) {
      this.products = JSON.parse(localStorage.getItem("cart"))
    //   this.product = JSON.parse(localStorage.getItem("cart"))
    }
 
    axios.get(this.base_url+'api/app/settings')
    .then(response => (this.settings = response.data))
    .catch(error => console.log(error))
       
   
  },
 
    created() {
        this.getCurrency();
        this.getCoupon();
    },
 
   methods: {
 
       removeProduct(product)
       {
            const cartItems = JSON.parse(localStorage.getItem("cart"));
            const index = cartItems.findIndex(({ id }) => id === product);
            cartItems.splice(index, 1);
            localStorage.setItem("cart", JSON.stringify(cartItems));
            this.cart = JSON.parse(localStorage.getItem("cart"));
            notify({
                type: "success",
                title: "Cart",
                text:  'Product Deleted successfully',
            })
            window.location.href = '/cart'
       },
 
       getCoupon(){
           if(this.coupon){
           this.$store.commit('loading', true)
           axios.get(this.base_url+'api/coupons/' + this.coupon)
            .then((response) => {
                this.$store.commit('loading', false)
                this.discount = response.data
            })
            // for(let product of this.$store.state.cart) {
            //     console.log(product.id)
            //     if(product == this.discount.products){
                    return this.discount
            //     }
            // }
           }
       },
 
        getCurrency() {
            this.$store.commit('loading', true)
            let setting;
            axios.get(this.base_url+'api/app/settings')
            .then( (response) => {
                this.$store.commit('loading', false)
                setting = response.data;
                this.currency = setting.strings.currency
            })
            return this.currency;
        },
 
        sanitizeTitle(title) {
            var slug = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
           
            return slug;
        },
 
 
        // increment(product) {
        //     if(product.available_qty > 1){
        //         this.quantity++;
        //         this.totalPrice = product.price + this.quantity;
        //     }
        // },
 
        // decrement() {
        //     if(this.quantity === 1)
        //     {
        //          notify({
        //             type: "error",
        //             title: "Cart",
        //             text: "You cannot add 0 product",
        //         });
        //     }else{
        //         this.quantity--
        //     }
        // },
 
    }
}
</script>
 
 
<style scoped>
.cart-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 20px;
    background-color: #fff;
    width: 100%;
}
.title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: bold;
}
.empty-cart {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}
 
.cart-content {
    display: flex; /* Use flexbox to arrange items in a row */
    flex-wrap: nowrap; /* Ensure items stay in a single line */
    overflow-x: auto; /* Allow horizontal scrolling */
    gap: 16px; /* Space between items */
}
 
.cart-items {
    flex: 0 0 auto; /* Prevent shrinking */
    margin-right: 20px; /* Space between items */
}
 
.cart-item {
    flex: 0 0 auto; /* Ensure items take their natural width */
    display: flex; /* Use flexbox for internal layout */
    flex-direction: row; /* Arrange child elements horizontally */
    align-items: center; /* Center items vertically */
    position: relative; /* For positioning the remove button */
    max-width: 100%; /* Ensure the item doesn’t exceed container width */
}
 
.remove-product-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(255,127,80);
    color: white;
    padding: 4px;
    border-radius: 4px;
}
 
.product-image {
    max-width: 100px;
    max-height: 80px;
    flex-shrink: 0; /* Prevent shrinking */
}
 
.product-details {
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    flex: 1; /* Allow details to take up remaining space */
    margin-left: 16px; /* Space between image and details */
}
 
.product-name {
    margin-top: 16px;
}
 
.vendor-name {
    margin-top: 4px;
}
 
.quantity-price, .price-info, .extras {
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
}
 
.order-summary {
    width: 100%;
    margin-top: 20px;
    margin-left: 50px; /* Add 50px gap on the left side */
    padding-bottom: 20px; /* Ensure padding at the bottom for spacing */
}
 
.summary-title {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 1rem;
    font-weight: 600;
    color: gray;
}
 
.summary-info {
    font-weight: bold;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 12px 0; /* Add padding for better spacing */
}
 
.checkout-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #f5f5f5; /* Optional background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional box shadow */
}
 
 
.coupon-button {
    width: 100%;
    padding: 12px;
    margin-top: 16px;
    font-weight: 600;
    border-width: 2px;
    border-radius: 24px;
    background-color: rgb(255,127,80);
    cursor: pointer;
}
 
</style>
 