<template>
    <div class="bg-black-stallion shadow" v-if="settings">
      <div class="container p-5 mx-auto max-w-7xl mt-14 md:p-10">
        <div class="grid gap-8 md:grid-cols-5" v-if="settings">
          <div class="mb-5">
            <a href="/" class="text-2xl font-bold text-white md:text-3xl">
              <img src="../assets/images/8.png" alt="Service Img" style="width: 120px; height: auto;">
            <!--  {{ settings.strings.app_name }}-->
            </a>
         
            <div class="flex flex-row mt-3 mb-3 space-x-3">
              <a :href="settings.strings.website.social ? settings.strings.website.social.fbLink : '#'">
                <img src="/img/icons/facebook.svg" class="w-6 h-6 filter-white hover:opacity-75" alt="Facebook">
              </a>
              <a :href="settings.strings.website.social ? settings.strings.website.social.igLink : '#'">
                <img src="/img/icons/instagram.svg" class="w-6 h-6 filter-white hover:opacity-75" alt="Instagram">
              </a>
              <a :href="settings.strings.website.social ? settings.strings.website.social.twLink : '#'">
                <img src="/img/icons/twitter.svg" class="w-6 h-6 filter-white hover:opacity-75" alt="Twitter">
              </a>
            </div>
          </div>
          <div class="flex flex-col mb-5 text-white">
            <p class="mb-2 font-semibold text-lg">Lomac Service Center</p>
            <div class="flex flex-col space-y-2 text-sm">
              <p class="mt-2 text-sm leading-relaxed text-gray-400">
              {{ settings.strings.website.websiteFooterBrief }}
            </p>
            </div>
          </div>
          
          <div class="flex flex-col mb-5 text-white">
            <p class="mb-2 font-semibold text-lg">Company</p>
            <div class="flex flex-col space-y-2 text-sm">
              <a class="" target="__blank" :href="`${this.$store.state.baseUrl}pages/contact`">Contact Us</a>
              <a class="" target="__blank" :href="`${this.$store.state.baseUrl}privacy/policy`">Privacy Policy</a>
              <a class="" target="__blank" :href="`${this.$store.state.baseUrl}pages/terms`">Terms & Conditions</a>
            </div>
          </div>
          
          <div class="flex flex-col mb-5 text-white">
            <p class="mb-2 font-semibold text-lg">Do Business with Us</p>
            <div class="flex flex-col space-y-2 text-sm">
              <!--<a class="" target="__blank" :href="`${this.$store.state.baseUrl}register#driver`">Need Riders or Drivers?</a>-->
              <a class="" target="__blank" :href="`${this.$store.state.baseUrl}register#vendor`">Become a Partner</a>
            </div>
          </div>
          
          <div class="flex flex-col mb-5 text-white">
            <p class="mb-2 font-semibold text-lg">Account</p>
            <div class="flex flex-col space-y-2 text-sm">
              <a class="" href="/profile">Manage Account</a>
              <a class="" href="/orders">Orders</a>
            </div>
            <div class="flex flex-col mt-4 space-y-2">
  <a :href="settings.strings.androidDownloadLink" target="__blank">
    <img src="/img/play-store.png" class="h-10"/>
  </a>
  <a :href="settings.strings.iosDownloadLink" target="__blank">
    <img src="/img/app-store.png" class="h-10"/>
  </a>
</div>

          </div>
          
       <!---  <div class="flex flex-col mb-5 text-white">
            <p class="mb-2 font-semibold text-lg">Service</p>
            <div class="flex flex-col space-y-2 text-sm">
              <a class="" target="__blank" :href="`${this.$store.state.baseUrl}pages/contact`">Contact Us</a>
              <a class="" href="/vendors">Find a Store</a>
              <a class="" href="#services">Services</a>
            </div>
          </div>-->
  
          <!--<div class="flex flex-col mb-5 text-white">
            <p class="mb-2 font-semibold text-lg">Categories</p>
            <div class="flex flex-col space-y-2 text-sm" v-for="vendor in vendors" :key="vendor.id">
              <a class="" :href="$router.resolve({ params: { id: vendor.id, slug: sanitizeTitle(`${vendor.slug}`) }}).href">{{ vendor.name }}</a>
            </div>
          </div>-->
        </div>
        <div class="py-4 text-xs text-gray-500 border-t border-gray-700 md:text-center">
          &copy; {{new Date().getFullYear()}} {{ settings.strings.app_name }}. All rights reserved.
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        settings: null,
        vendors: null,
        base_url: this.$store.state.baseUrl
      };
    },
    mounted() {
      this.$store.commit('loading', true);
      axios.get(this.base_url + 'api/app/settings')
        .then((response) => {
          this.$store.commit('loading', false);
          this.settings = response.data;
        })
        .catch(error => console.log(error));
          
      axios.get(this.base_url + 'api/vendor/types')
        .then((response) => {
          this.vendors = response.data;
        })
        .catch(error => console.log(error));
    },
    methods: {
      sanitizeTitle(title) {
        var slug = "";
        var titleLower = title.toLowerCase();
        slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
        slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
        slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
        slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
        slug = slug.replace(/đ/gi, 'd');
        slug = slug.replace(/\s*$/g, '');
        slug = slug.replace(/\s+/g, '-');
        return slug;
      }
    }
  };
  </script>
  
  <style>
  .filter-white {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }
  
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  </style>
  