<template>
  <div class="container">
    <div class="main-wrapper">
      <!-- Register Content -->
      <div class="register-content">
        <form class="form" @submit.prevent="submit">
          <p class="form-title">Register</p>
 
          <div class="form-group">
            <label class="form-label" for="name">Name</label>
            <input type="text" id="name" v-model="details.name" placeholder="John Doe" class="form-input">
          </div>
 
          <div class="form-group">
            <label class="form-label" for="email">Email</label>
            <input type="email" id="email" v-model="details.email" placeholder="mail@example.com" class="form-input">
          </div>
 
          <div class="form-group phone-input">
            <label class="form-label" for="phone">Phone</label>
            <vue-tel-input class="phone-code" v-model="details.country_code" v-bind="bindProps"></vue-tel-input>
            <input type="tel" id="phone" v-model="details.phone" placeholder="0800000000" class="form-input">
          </div>
 
          <div class="form-group">
            <label class="form-label" for="password">Password</label>
            <input type="password" id="password" v-model="details.password" placeholder="********************" class="form-input">
          </div>
 
          <div v-if="!checked" class="btn-disabled">Register</div>
          <button v-else @click="register" class="btn-register">Register</button>
 
          <div class="terms-login-container">
            <label class="terms-label">
              <input type="checkbox" v-model="checked" class="terms-checkbox">
              <a target="__blank" :href="`${this.$store.state.baseUrl}pages/terms`" class="terms-text terms-link">Terms & Conditions</a>
            </label>
            <div class="login-link">
              <p class="login-text">Already have an account?</p>
              <router-link to="/login" class="login-link-text">Login</router-link>
            </div>
          </div>
        </form>
      </div>
      <!-- Image Section -->
     <!-- <div class="image-section">
        <img src="../assets/images/Register.png" alt="Register Image" />
      </div>-->
    </div>
  </div>
</template>
 
 
 
 
 
 
 
<script>
import axios from 'axios'
import Download from '@/components/Downloads.vue'
import { VueTelInput } from 'vue-tel-input'
import { notify } from "@kyvg/vue3-notification"
export default {
  name: "Home",
  components: {
    VueTelInput,
    Download,
  },
  data: function() {
    return {
      details: {
        name: null,
        email: null,
        password: null,
        phone: null,
        country_code: null
      },
      checked : false,
      settings: null,
      base_url: this.$store.state.baseUrl,
      bindProps: {
        mode: "international",
        defaultCountry: "GH",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        // placeholder: "Enter a phone number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "on",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: true
        }
      }
    };
  },
  mounted() {
 
    axios.get(this.base_url+'api/app/settings')
    .then(response => (this.settings = response.data))
    .catch(error => console.log(error))
  },
 
  methods: {
 
    register()
    {
      this.$store.commit('loading', true)
      axios.post(this.base_url+'api/register',this.details)
      .then((response) => {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("user",JSON.stringify(response.data.user));
        console.log(response)
          notify({
          type: "success",
          title: "Register",
          text: response.data.message,
        });
        window.location.href = "/"
      })
      .catch((error) => {
        // if(error.response) {
          // if(error.response.status == '400')
          // {
            notify({
              type: "error",
              title: "Registration failed",
              text:  "Email or phone already taken",
            })
          // }
        // }
      })
      .finally(() => this.$store.commit('loading', false))
    }
   
    // ...mapActions("auth", ["sendRegisterRequest"]),
 
    // register: function() {
    //   this.$store.commit('loading', true)
    //   this.sendRegisterRequest(this.details, ).then(() => {
    //     this.$store.commit('loading', false)
    //   });
    // },
  }
};
</script>
 
 
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}
 
.main-wrapper {
  display: flex;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  margin-top: 30px;  /* Added top margin */
  margin-bottom: 30px; /* Added bottom margin */
}
 
.register-content {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
 
.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}
 
.image-section img {
  max-width: 100%;
  height: auto;
}
 
/* Form Styles */
.form {
  display: flex;
  flex-direction: column;
}
 
.form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
 
.form-group {
  margin-bottom: 20px;
}
 
.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
 
.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
 
.phone-input {
  display: flex;
  align-items: center;
}
 
.phone-code {
  margin-right: 10px;
}
 
.terms-label {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
 
.terms-checkbox {
  margin-right: 8px;
}
 
.terms-text {
  font-size: 14px;
}
 
.terms-link {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
 
.btn-register {
  width: 100%;
  padding: 10px;
  background-color: rgb(153,0,0);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}
 
.btn-disabled {
  width: 100%;
  padding: 10px;
  background-color: #ccc;
  color: white;
  border-radius: 4px;
  text-align: center;
}
 
.terms-login-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
 
.login-link {
  display: flex;
  align-items: center;
}
 
.login-text {
  font-size: 14px;
  font-weight: normal;
  margin-right: 5px;
}
 
.login-link-text {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
 
.login-link-text:hover {
  text-decoration: underline;
}
 
/* Media Queries */
@media (max-width: 1024px) {
  .main-wrapper {
    flex-direction: column;
    margin-top: 20px; /* Reduced top margin for medium screens */
    margin-bottom: 20px; /* Reduced bottom margin for medium screens */
  }
 
  .image-section {
    display: none; /* Hide the image section on medium screens */
  }
}
 
@media (max-width: 768px) {
  .main-wrapper {
    flex-direction: column;
    margin-top: 15px; /* Further reduced top margin for small screens */
    margin-bottom: 15px; /* Further reduced bottom margin for small screens */
  }
 
  .register-content {
    padding: 20px; /* Reduced padding for small screens */
  }
 
  .form-title {
    font-size: 20px; /* Smaller font size for small screens */
  }
 
  .form-label {
    font-size: 14px; /* Smaller font size for form labels */
  }
 
  .form-input {
    padding: 8px; /* Reduced padding inside input fields */
  }
 
  .btn-register, .btn-disabled {
    padding: 12px; /* Increased padding for better touch interaction */
  }
 
  .terms-text {
    font-size: 12px; /* Smaller font size for terms text */
  }
 
  .login-text {
    font-size: 12px; /* Smaller font size for login text */
  }
}
 
@media (max-width: 480px) {
  .main-wrapper {
    margin-top: 10px; /* Minimal margin for very small screens */
    margin-bottom: 10px; /* Minimal margin for very small screens */
  }
 
  .register-content {
    padding: 15px; /* Further reduced padding for very small screens */
  }
 
  .form-title {
    font-size: 18px; /* Smaller font size for very small screens */
  }
 
  .form-label {
    font-size: 12px; /* Smaller font size for form labels */
  }
 
  .form-input {
    padding: 6px; /* Further reduced padding inside input fields */
  }
 
  .btn-register, .btn-disabled {
    padding: 10px; /* Adjust padding for very small screens */
  }
 
  .terms-text {
    font-size: 10px; /* Smaller font size for terms text */
  }
 
  .login-text {
    font-size: 10px; /* Smaller font size for login text */
  }
}
</style>
 